import { useState } from "react";
import { Link } from "react-router-dom";
import HeaderBtnDownIcon from "../assets/img/header-btn-arrow-down.png";
import HeaderBtnUpIcon from "../assets/img/header-btn-arrow-up.png";

const SOCIAL_LINKS = {
  X_URL: "https://x.com/FLIPBIGMILK",
  TELEGRAM_URL: "https://t.co/mQyq2Ck8Mw",
  BUY_URL: "https://raydium.io/swap/?inputMint=6xaLVgohU7ztwWT6GJ7o2vVaVGjzHUJtrY742aBhpump&outputMint=sol"
};

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Desktop Menu */}
      <div className="sm:flex text-[#ffffff] bg-[#000000] m-0 p-0 items-center hidden top-0 sticky z-50">
        <Link to="/" className="w-1/4 hover:bg-[#ffffff] hover:text-[#000000] text-[20px] p-[10px] block">
          HOME
        </Link>
        
        <a href={SOCIAL_LINKS.X_URL} target="_blank" rel="noopener noreferrer" 
          className="w-1/4 hover:bg-[#ffffff] hover:text-[#000000] text-[20px] p-[10px]">
          X
        </a>
        
        <a href={SOCIAL_LINKS.TELEGRAM_URL} target="_blank" rel="noopener noreferrer"
          className="w-1/4 hover:bg-[#ffffff] hover:text-[#000000] text-[20px] p-[10px]">
          TELEGRAM
        </a>
        
        <a href={SOCIAL_LINKS.BUY_URL} target="_blank" rel="noopener noreferrer"
          className="w-1/4 hover:bg-[#ffffff] hover:text-[#000000] text-[20px] p-[10px]">
          BUY $M0000
        </a>
      </div>

      {/* Mobile Menu */}
      <div className="flex text-[#ffffff] bg-[#000000] m-0 p-0 items-center sm:hidden top-0 sticky z-50">
        <div className="relative w-full">
          <div className="p-[10px]" onClick={toggleDropdown}>
            <img
              src={HeaderBtnDownIcon}
              alt="Down Icon"
              className={`w-[15px] ${isOpen ? "hidden" : "block"}`}
            />
            <img
              src={HeaderBtnUpIcon}
              alt="Up Icon"
              className={`w-[15px] ${isOpen ? "block" : "hidden"}`}
            />
          </div>

          {/* Dropdown Menu */}
          <div className={`absolute left-0 ${isOpen ? "block" : "hidden"} bg-[#000000] text-[#ffffff] w-full p-0 m-0`}>
            <Link to="/" className="hover:bg-[#ffffff] hover:text-[#000000] text-[12px] p-[10px] block" onClick={() => setIsOpen(false)}>
              HOME
            </Link>
            
            <a href={SOCIAL_LINKS.X_URL} target="_blank" rel="noopener noreferrer"
              className="hover:bg-[#ffffff] hover:text-[#000000] text-[12px] p-[10px] block" onClick={() => setIsOpen(false)}>
              X
            </a>
            
            <a href={SOCIAL_LINKS.TELEGRAM_URL} target="_blank" rel="noopener noreferrer"
              className="hover:bg-[#ffffff] hover:text-[#000000] text-[12px] p-[10px] block" onClick={() => setIsOpen(false)}>
              TELEGRAM
            </a>
            
            <a href={SOCIAL_LINKS.BUY_URL} target="_blank" rel="noopener noreferrer"
              className="hover:bg-[#ffffff] hover:text-[#000000] text-[12px] p-[10px] block" onClick={() => setIsOpen(false)}>
              BUY $M0000
            </a>
          </div>
        </div>
      </div>
    </>
  );
}