import { useState } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Button } from "../components";

import CiaLogoImg from "../assets/img/home-cia-logo.svg";
import CowImg from "../assets/img/home-cow.svg";
import DiamondImg from "../assets/img/home-diamond.svg";
import AccessibilityImg from "../assets/img/home-accessibility.svg";
import QuestionImg from "../assets/img/home-question.svg";

const moduleVideoUrl =
  "https://www.dropbox.com/scl/fi/6892shc0nfihxcsaih3bt/Module-A4928.mp4?rlkey=igxk2hnmpw9515rszftgb4uxp&st=7x0dlaj1&raw=1";

export function Home() {
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="bg-[#FAFAF8] flex flex-col items-center gap-[70px] px-[23px]">
      {/* -- Header -- */}

      {/* -- Main Content -- */}
      <section class="flex flex-col gap-[30px] mt-[70px] max-w-[1150px] main-section">
        <div className="flex flex-row sm:gap-[32px] gap-[20px] justify-between">
          <img
            src={CiaLogoImg}
            className="sm:w-[117px] w-[75px]"
            alt="cia-logo"
          />
          <p className="sm:text-[40px] text-[18px] text-[#5B4949]">
            <span>DECLASSIFIED: REQUEST OF</span>
            <br className="sm:hidden block" />
            <span className="bg-[#5B4949] w-full sm:px-[200px] px-[92px]"></span>
            <br />
            <span className="uppercase">
              Cultural biohacking project #a0392
            </span>
          </p>
        </div>
        <div className="flex lg:flex-row md:flex-col flex-row sm:ml-[149px] gap-[10px] justify-between">
          <p className="sm:text-[20px] text-[12px] text-[#5B4949] leading-tight max-w-[730px]">
            <span className="bg-[#5B4949] sm:px-[200px] px-[80px]"></span>
            <span>cold war ultimate weapon,</span>
            <span> discovered during Operation Paperclip by</span>
            <span className="bg-[#5B4949] sm:px-[120px] px-[50px]"></span>
            <br />
            <span className="bg-[#5B4949] sm:px-[220px] px-[100px]"></span>
            <span>has been deprecated </span>
            <br />
            <span className="bg-[#5B4949] w-full sm:px-[320px] px-[170px]"></span>
            <br />
            <span>
              and other cognitive breakthroughs during Project Code: MK Ultra.
            </span>
          </p>
          <img src={CowImg} alt="cow" width={154} className="sm:block hidden" />
        </div>
        <div className="flex justify-center">
          <Button
            text="BEGIN COGNITIVE MESMERISM MODULE #A4928"
            onClick={() => {
              console.log("button clicked", showVideo);
              setShowVideo(true);
            }}
          />
        </div>
        <p className="sm:text-[20px] text-[12px] text-[#5B4949] leading-tight max-w-[800px] mx-auto sm:text-center">
          <span>WARNING:</span>
          <span className="bg-[#5B4949] sm:px-[130px] px-[86px]"></span>
          <span>and</span>
          <span className="bg-[#5B4949] sm:px-[140px] px-[0px]"></span>
          <br />
          <span> approve subjects available for testing</span>
          <span className="bg-[#5B4949] sm:px-[120px] px-[22px]"></span>
          <br />
          <span className="bg-[#5B4949] sm:px-[80px] px-[26px]"></span>
          <span className="sm:inline hidden">
            range of adverse effects in public applications.
          </span>
          <span className="inline sm:hidden">
            % of adverse effects in public applications.
          </span>
        </p>
      </section>

      <section>
        <div className="flex flex-row justify-center mb-[50px] block sm:hidden">
          <img src={CowImg} alt="cow" width={154} className="" />
        </div>
        <p class="sm:text-[30px] text-[18px] mb-[20px] text-center">
          Non-Military Applications
        </p>
        <div class="grid sm:grid-cols-3 grid-cols-1 gap-8 sm:text-[20px] text-[16px]">
          <div class="flex flex-col items-center px-2">
            <img src={DiamondImg} alt="diamond" width={45} />
            <p class="mt-2">Project Code: Diamond Hooves</p>
            <p>Location: New Delhi, India</p>
            <p>Status: Success</p>
            <Link to="/diamond" className="text-[#5B4949]">
              SEE FILES
            </Link>
          </div>

          <div class="flex flex-col items-center px-2">
            <img src={AccessibilityImg} alt="accessibility" width={45} />
            <p class="mt-2">Project Code: Flip Big Milk</p>
            <p>Location: Wisconsin, USA</p>
            <p>Status: Approved</p>
            <Link to="/flip" className="text-[#5B4949]">
              SEE FILES
            </Link>
          </div>

          <div class="flex flex-col items-center px-2">
            <img src={QuestionImg} alt="question" width={45} />
            <p class="mt-2">Project Code: Classified</p>
            <p>Location: Classified</p>
            <p>Status: Pending Approval</p>
            <p>NOT AVAILABLE</p>
          </div>
        </div>
      </section>

      <section class="mb-[70px] px-[20px]">
        <p class="sm:text-[30px] text-[18px] text-center mb-6">
          DECLASSIFIED PUBLICATIONS
        </p>
        <p class="sm:text-[20px] text-[12px]">
          Neuroscience and Cultural Symbols:
          <br />
          &nbsp;&nbsp;•&nbsp; Han, S., & Northoff, G. (2008). Culture-sensitive
          neural substrates of human cognition: A transcultural neuroimaging
          approach. Nature Reviews Neuroscience, 9(8), 646–654.
        </p>
        <p class="sm:text-[20px] text-[12px]">
          Cryptoeconomics and Behavioral Finance:
          <br />
          &nbsp;&nbsp;•&nbsp; Shiller, R. J. (2017). Narrative economics.
          American Economic Review, 107(2), 967–1004.
        </p>
        <p class="sm:text-[20px] text-[12px]">
          Fermat’s Last Theorem:
          <br />
          &nbsp;&nbsp;•&nbsp; Wiles, A. (1995). Modular elliptic curves and
          Fermat’s Last Theorem. Annals of Mathematics, 141(3), 443–551.
        </p>
      </section>

      {showVideo && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Dimmed background */}
          <div
            className="absolute inset-0 bg-black/70"
            onClick={() => setShowVideo(false)}
          ></div>

          {/* Video Container */}
          <div className="relative bg-black rounded-lg p-1 max-w-3xl w-full">
            <video className="w-full rounded-lg" controls autoPlay>
              <source src={moduleVideoUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>

            {/* Close Button */}
            <button
              onClick={() => setShowVideo(false)}
              className="absolute top-2 right-2 bg-gray-500 text-white rounded-full w-8 h-8 flex items-center justify-center hover:bg-gray-700"
            >
              ✕
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
