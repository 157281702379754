export default function Button({ text, onClick }) {
  return (
    <button
      className="bg-[#ffffff] hover:animate-strobe text-center box-border px-[5px] sm:text-[30px] text-[13px]"
      onClick={onClick}
    >
      {text}
    </button>
  );
}
