import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Button } from "../components";

import CiaLogoImg from "../assets/img/home-cia-logo.svg";
import CowImg from "../assets/img/home-cow.svg";

export function Diamond() {
  return (
    <div className="bg-[#FAFAF8] flex flex-col items-center sm:gap-[60px] gap-[20px] sm:py-[60px] py-[20px] sm:px-[32px] px-[20px]">
      <div className="flex flex-row gap-[32px] uppercase">
        <img
          src={CiaLogoImg}
          alt="cia-logo"
          className="sm:w-[100px] w-[75px]"
        />
        <p>
          <span className="text-[#5B4949] sm:text-[32px] text-[18px]">
            DECLASSIFIED PROJECT CODE: DIAMOND HOOVES
            <span className="sm:block hidden">| BULLETIN #0001</span>
          </span>
          <span className="text-[26px] sm:block hidden">
            OBJECTIVE: COGNITIVE augmentation through cultural symbolism
            (Success)
          </span>
        </p>
        <img src={CowImg} alt="cow" className="w-[100px] sm:block hidden" />
      </div>
      <img src={CowImg} alt="cow" className="w-[155px] block sm:hidden" />
      <p className="max-w-[1150px] uppercase sm:text-[20px] text-[12px] text-[#E60000]">
        THE FOLLOWING content has been compiled from third party sources. Due to
        FOIA request #4392-B we are required by law to provide this disclaimer
        that the technologies used in Project Code: Diamond Hooves is the same
        non-militarized, patented technology in operation for Project Code: Flip
        Big Milk.
      </p>
      <div className="flex flex-col gap-[26px] max-w-[1150px] uppercase sm:text-[20px] text-[12px]">
        <p>
          In the heart of Mumbai's bustling financial district stands Genetica
          Labs, where an unprecedented collaboration between German
          neuroscientists and Indian policymakers has unveiled what many are
          calling the{" "}
          <span className="font-bold">
            most controversial breakthrough in cognitive manipulation
          </span>{" "}
          since the advent of social media.
        </p>
        <p>
          At the center of this discovery lies what researchers have dubbed
          "Krishna's Karma" – a phenomenon that marries ancient Hindu symbolism
          with precise neurological triggering. By harnessing the deeply rooted
          cultural significance of the sacred cow through carefully engineered
          digital stimuli, scientists claim they can fundamentally alter human
          decision-making patterns, particularly in financial behaviors.
        </p>
        <p className="font-bold">
          The implications of this discovery have sent shockwaves through both
          scientific and ethical communities worldwide.
        </p>
        <p>
          Through exclusive access to Genetica Labs' classified research
          facilities, this documentary explores how a simple animated GIF of a
          cow became the key to unlocking – and potentially reprogramming – the
          collective consciousness of a billion people.
        </p>
      </div>
    </div>
  );
}
