import "@fontsource/ibm-plex-mono";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Header, Footer } from "./components";
import { Diamond, Flip, Home } from "./pages";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/diamond" element={<Diamond />} />
        <Route path="/flip" element={<Flip />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
